<template>
    <section class="px-4 pt-4">
        <cargando v-if="cargando" />
        <div class="row mx-0 align-items-center mb-3">
            <div class="back d-middle-center cr-pointer" @click="$router.back()">
                <i class="icon-left f-14" />
            </div>
            <div class="col-auto f-18 f-500 text-general">
                Importar archivo de cambio de precio (CEDIS: {{ info_cedis.nombre }})
            </div>
            <div class="btn-grey px-3 f-14 ml-auto" @click="cancelar">
                Cancelar
            </div>
            <div class="btn-general px-3 f-14 ml-2" @click="confirmarCambioPrecio">
                Importar precios
            </div>
        </div>
        <div class="row mx-0 align-items-center mt-4 py-3 bg-white br-t-8">
            <div class="border py-1 br-10 mr-3 d-middle px-3 f-14 ml-2 text-general" @click="seleccionarCedis">
                <p> Aplicar en otros cedis </p>
                <i class="icon-angle-down f-20" />
            </div>
            <p v-for="(c,key) in grupoCedis" :key="key" class="text-general f-600"> {{ c.nombre }} - </p>
        </div>
        <div class="row mx-0 align-items-center mt-3 py-3 bg-white br-t-8 ">
            <div class="col-auto f-18 f-500 ml-3 text-general">
                Productos para cambio de precio
            </div>
            <div class="col-3 ml-auto">
                <el-input v-model="buscar" placeholder="Buscar" size="small" class="w-100 br-20" />
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12 px-0">
                <el-table
                :data="datos.filter(data => !buscar || data.nombre_producto.toLowerCase().includes(buscar.toLowerCase()))"
                header-row-class-name="text-general f-17"
                style="width: 100%"
                >
                    <el-table-column
                    width="80"
                    >
                        <template slot-scope="scope">
                            <div class="row mx-0 justify-center">
                                <el-tooltip v-if="scope.row.estado == 0" placement="bottom" content="Este cedis no" effect="light">
                                    <div slot="content">
                                        <p>
                                            Este producto esta <br />
                                            inactivo en este cedis
                                        </p>
                                    </div>
                                    <i class="icon-attention-alt text-general-red f-20" />
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="barcode_o_sku"
                    :label="tipo === 'sku' ? 'Sku' : 'Barcode'"
                    width="160"
                    />

                    <el-table-column
                    label="Productos"
                    width="160"
                    >
                        <template slot-scope="scope">
                            <p v-if="scope.row.producto_existe" class="f-14">
                                {{ scope.row.nombre_producto }}
                            </p>
                            <div v-else class="row mx-0">
                                <el-tooltip placement="bottom" content="No se encontró el producto" effect="light">
                                    <i class="icon-attention-alt text-general-red f-20" />
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Precio Actual"
                    width="180"
                    >
                        <template slot-scope="scope">
                            <p class="f-14">
                                {{ separadorNumero(scope.row.precio_actual) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="actualizado_por"
                    label="Actualizado por"
                    width="180"
                    />
                    <el-table-column
                    label="Fecha"
                    width="140"
                    >
                        <template slot-scope="scope">
                            <p v-if="scope.row.fecha" class="f-14">
                                {{ scope.row.fecha | helper-fecha('DD - MMM Y') }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Cantidad actual"
                    class-name="text-center"
                    width="200"
                    >
                        <template slot-scope="scope">
                            <p class="f-14">
                                {{ agregarSeparadoresNumero(scope.row.cantidad_actual) }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                    label="Precio nuevo"
                    width="325"
                    >
                        <template slot-scope="scope">
                            <div class="row mx-0 align-items-center">
                                <div class="col-1 px-0 mr-2">
                                    <el-tooltip v-if="!scope.row.precio_ok" placement="bottom" content="Precio no valido" effect="light">
                                        <i class="icon-attention-alt text-general-red f-20" />
                                    </el-tooltip>
                                </div>
                                <div class="col-8 px-0">
                                    <div class="d-middle" @change="actualizarValor(scope.row)">
                                        <money v-model="scope.row.precio_nuevo" class="input-money" v-bind="money" />
                                    </div>
                                    <p v-show="!scope.row.precio_ok" class="f-12 text-danger pl-2 mt-1">
                                        <el-tooltip placement="bottom" content="Precio ingresado" effect="light">
                                            <i class="icon-attention-alt text-general-red" />
                                        </el-tooltip>
                                        {{ scope.row.precio_malo }}
                                    </p>
                                </div>
                                <el-tooltip placement="bottom" content="Eliminar registro" effect="light">
                                    <div class="btn-action border ml-3 cr-pointer" @click="eliminarRegistro(scope.$index)">
                                        <i class="icon-delete-outline f-18" />
                                    </div>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- Partials -->
        <modal-eliminar ref="modalEliminarRegistro" titulo="Eliminar registro" mensaje="¿Desea eliminar este registro?" @eliminar="irEliminar" />
        <modal ref="modalCancelar" titulo="Cancelar proceso" no-aceptar adicional="Confirmar" @adicional="irCancelar">
            <div class="row mx-0 justify-center">
                <div class="col-auto text-general">
                    ¿Desea cancelar el proceso?
                </div>
            </div>
        </modal>
        <modal ref="modalCambiarPrecios" :titulo="tituloCambioPrecios" no-aceptar adicional="Cargar" @adicional="importarPrecios">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center">
                    <div class="col-12">
                        <ValidationProvider v-slot="{errors}" rules="required" name="Descripcion">
                            <el-input
                            v-model="descripcion"
                            type="textarea"
                            :rows="7"
                            placeholder="Justificación"
                            />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modal ref="modalCambioPrecioCedis" titulo="Selección de cedis" no-aceptar adicional="Aplicar" @adicional="seleccionarCedis">
            <div class="row mx-0 justify-center">
                <div class="col-auto text-general">
                    Seleccione los CEDIS que desea aplicar estos cambios de precio
                </div>
            </div>
            <div class="row mx-0 align-items-center">
                <el-checkbox-group v-model="grupoCedis">
                    <el-checkbox v-for="(c,key) in cedis" :key="key" :label="c" class="col-12 text-general my-1">
                        {{ c.nombre }}
                    </el-checkbox>>
                </el-checkbox-group>
            </div>
        </modal>
    </section>
</template>

<script>
import {Money} from 'v-money'
import ProductosAdmin from "~/services/productos/productosAdmin"
import {mapGetters} from 'vuex'
export default {
    components:{
        Money,
    },
    data(){
        return {
            cargando: false,
            datos: [],
            grupoCedis: [],
            datos_validos: [],
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            buscar: '',
            descripcion: '',
            tituloCambioPrecios: '',
            index_eliminar: '',
        }
    },
    computed: {
        ...mapGetters({
            info_cedis:'cedis/info_cedis',
        }),
        id_cedis(){
            return this.$route.params.id_cedis;
        },
        datos_importados(){
            return this.$route.params.datos;
        },
        cedis(){
            return  this.$route.params.cedis.filter(el => el.id != this.$route.params.id_cedis)
        },
        tipo(){
            return this.$route.params.tipo;
        },
    },
    mounted(){
        this.money.precision = this.getDecimales(this.id_cedis)
        this.datos = this.datos_importados
    },
    methods: {
        actualizarValor(datos){
            let info = this.datos.find((item, index) => item.id_producto === datos.id_producto );
            if (!isNaN(info.precio_nuevo) && info.precio_nuevo > 0){
                info.precio_ok = true;
                return;
            }
            info.precio_ok = false;
            return;
        },
        async importarPrecios(){
            try {
                if (this.id_cedis === null || this.id_cedis === undefined || this.id_cedis === ''){return}
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    return this.notificacion('Mensaje', 'Debe completar el campo justificación', 'warning')
                }
                let form = {
                    id_cedis: this.id_cedis,
                    data: this.datos_validos,
                    descripcion: this.descripcion,
                    cedis: _.map(this.grupoCedis, 'id')
                };
                const {data} = await ProductosAdmin.postCambioPreciosProductos(form)
                this.$refs.modalCambiarPrecios.toggle();
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$router.push({name: 'admin.cedis.productos'});
            } catch (error){
                this.error_catch(error)
            }
        },
        confirmarCambioPrecio(){
            this.datos_validos = this.datos.filter(
                (item, index) => item.producto_existe === true && item.precio_ok === true && item.cedis_vende_producto === true
            );
            if (this.datos_validos.length < 1){
                return this.notificacion('Mensaje', 'No existen productos que cumplan con las validaciones para el cargue', 'warning');
            }
            this.tituloCambioPrecios = 'Se cargaran '+this.datos_validos.length+" de "+this.datos.length+".";
            this.$refs.modalCambiarPrecios.toggle();
        },
        eliminarRegistro(index){
            this.index_eliminar = index;
            this.$refs.modalEliminarRegistro.toggle();
        },
        cancelar(){
            this.$refs.modalCancelar.toggle();
        },
        irCancelar(){
            this.$refs.modalCancelar.toggle();
            this.$router.push({name: 'admin.cedis.productos'});
        },
        irEliminar(){
            this.datos.splice(this.index_eliminar, 1);
            this.index_eliminar = '';
            this.$refs.modalEliminarRegistro.toggle();
        },
        seleccionarCedis(){
            this.$refs.modalCambioPrecioCedis.toggle();
        },
    }
}
</script>
<style lang="scss" scoped>
.back{
    width: 20px;
    height: 20px;
    color: #FFFFFF;
    border-radius: 50%;
    background-color: #000000;
}
</style>
